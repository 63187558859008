$colors: (
  white: #fff,
  primary: #2868C5,
  secondary:#5C91D2,
  nevyblue: #294363,
  lightgray: #C4C6D0,
  gray: #969696,
  darkgray: #7D7C7C,
  bordercolor: #E0DFDF,
  transparent: transparent,
);
$maxLoop: 100;
$step: 5;
