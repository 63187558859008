@import "./helper.scss";
@import "./variable.scss";
@import "./mixins.scss";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
body {
  margin: 0;
  padding: 0 !important;
  line-height: 1.3 !important;
  font-family: "Manrope", sans-serif;
  scroll-behavior: smooth;
}
